import { DateTime } from "luxon";
import { EmailRegex, SupportedTerritories } from "../constants/Constants";
import { UserSessionUtils } from "./UserSessionUtils";
//import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { FAILED_TO_LOAD_MESSAGE, INTERNAL_SERVER_ERROR } from "../constants/ErrorMessages";
//import { Badge } from "react-bootstrap";

/**
 * This formats a date from the Calendar object into the yyyy-mm-dd format
 * @param {*} date
 * @returns
 */
export function formatJSDate(date, showTime = false) {
  if (date == null) return null;
  if (!showTime) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  } else {
    return new Date(date).toISOString();
  }
}

export function formatToStandardDate(date) {
  date = new Date(date);

  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
  return day + "/" + month + "/" + year;
}

/**
 * This converts a number to the en-us locale to put commas in the string
 *
 * @param {Number} amount
 * @returns
 */
export function formatAmountWithCommas(amount, currency) {
  if (amount) {
    var formattedAmount = amount.toLocaleString();
    return currency == null ? formattedAmount : currency + " " + formattedAmount;
  } else return "0";
}

/**
 * This converts a number to the en-us locale to put commas in the number
 *
 * @param {Number} number
 * @returns {String}
 */
export function formatNumberWithCommas(number, defaultNumber = "-") {
  if (number) {
    return number.toLocaleString();
  } else {
    return defaultNumber;
  }
}

/**
 * This converts a string of a date time instance to a date time string using the
 * luxon DateTime library
 *
 * @param {string} date_value
 * @returns {string}
 */
export function toReadableDate(date_value, date_only = false) {
  if (date_value == null) {
    return "-";
  } else if (date_value && date_only) {
    return DateTime.fromISO(date_value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  } else if (date_value && !date_only) {
    return DateTime.fromISO(date_value).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  }
}

export function convertEpochToReadableDateTime(epoch_time) {
  return new Date(epoch_time * 1000);
}

/**
 * This returns the current date time into an ISO string
 */
export function getCurrentDateTimeISOFormat() {
  return DateTime.now().toISO();
}

/**
 * This returns the first date of the current month
 */
export function getFirstDateOfMonth() {
  return DateTime.local().startOf("month").toISODate();
}

/**
 * This returns the current date into an ISO string
 */
export function getCurrentDateISOFormat() {
  return DateTime.now().toISODate();
}

export function getDateFromString(dateString) {
  return DateTime.fromISO(dateString);
}

/**
 * This method is used to get a date object from all passed date string.
 *
 * @param dateString
 * @returns
 */
export function getDateWithAnyString(dateString) {
  console.log(dateString);
  if (dateString == null) return null;
  console.log(dateString);
  return new Date(dateString);
}

/**
 * This gets the date before by subtracting the number of days from the current date
 * @param numberOfDays
 * @returns
 */
export function getDateBeforeByDays(numberOfDays) {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - numberOfDays
  );
}

/**
 * Check if the dictionary is empty
 * @param obj
 * @returns
 */
export function isDictEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function replaceWithUnderscore(value) {
  return value.replace(/\s+/g, "_").toLowerCase();
}

/**
 * This converts a boolean to a Yes or No string
 *
 * @param {Boolean} field
 * @returns
 */
export function formatBooleanFields(field) {
  if (field === null) return "-";
  else if (field === true) return "Yes";
  else if (field === false) return "No";
}

export function sanitizeValue(recordValue) {
  return recordValue === null || recordValue === undefined ? "-" : recordValue;
}

/**
 * Generate random Id to be used as a device Id.
 *
 * @param length
 * @returns
 */
export function generateId(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * This formats a string to replace string contents with the arguments index e.g.
 * formatString("{0} is {1} and {2}", "This", "Great", "Wonderful") would return
 * "This is Great and Wonderful".
 */
export function formatString(str, ...replacements) {
  for (let index = 0; index < replacements.length; index++) {
    str = str.replace(`{${index}}`, replacements[index]);
  }
  return str;
}

/**
 * This splits the supplied string by commas and returns a valid string
 * @param {string} commaSeparatedString
 * @returns {Array} an array of strings
 */
export function splitCommaSeparatedString(commaSeparatedString) {
  if (commaSeparatedString !== null) {
    return commaSeparatedString.split(",").map((item) => {
      return item.trim();
    });
  }
  return [];
}

/**
 * This validates a string using the libphonenumber js lib through the supported territories
 *
 * @param {string} phoneNumber
 * @returns
 */
export function validatePhoneNumber(phoneNumber) {
  var result = false;
  SupportedTerritories.forEach((territory) => {
    if (isValidPhoneNumber(phoneNumber, territory.countryIsoCode)) {
      result = true;
    }
  });
  return result;
}

/**
 * This adds a plus character to a phone number string after checking if the first character is a +
 *
 * @param {string} phoneNumber
 */
export function addPlusCharacterToPhoneNumber(phoneNumber) {
  if (phoneNumber.charAt(0) !== "+") {
    return "+" + phoneNumber;
  }
  return phoneNumber;
}

/**
 * This validates a string using the libphonenumber js lib to make sure it is in an international format
 *
 * @param {string} phoneNumber
 */
export function validateInternationalPhoneNumber(phoneNumber) {
  var result = false;
  try {
    var parsedPhoneNumber = parsePhoneNumber(addPlusCharacterToPhoneNumber(phoneNumber));
    if (
      parsedPhoneNumber != null &&
      SupportedTerritories.filter((e) => e.countryIsoCode === parsedPhoneNumber.country).length >
        0 &&
      parsedPhoneNumber.isValid()
    ) {
      result = true;
    }
  } catch (error) {
    result = false;
  }
  return result;
}

/**
 * This validates an email string using a regular expression
 *
 * @param {string} email
 * @returns
 */
export function validateEmail(email) {
  return email.match(EmailRegex);
}

/**
 * This returns the value from a key value list of dictionaries
 * @param arr
 * @param key
 * @returns
 */
export function getArrayKeyValue(arr, key) {
  let value = null;

  for (const element of arr) {
    if (element?.key === key) {
      value = element?.value;
      break;
    }
  }

  return value;
}

/**
 * This checks whether a user has a specific permission
 * @param permission
 * @returns boolean
 */
export function userHasPermission(permissionName) {
  let userDetails = UserSessionUtils.getUserDetails();
  if (userDetails?.isASuperAdmin) {
    return true;
  }
  return userDetails?.permissionLists?.some(
    (userPermission) => userPermission.permissionName === permissionName
  );
}

/**
 * This checks whether a user has a specific permission using the permission Id. This is better since it is an enum
 * value in the back office. Use a combination of permission Id and Module
 * @param permission
 * @returns boolean
 */
export function userHasPermissionId(module, permissionId) {
  let userDetails = UserSessionUtils.getUserDetails();
  if (userDetails?.isASuperAdmin) {
    return true;
  }
  return userDetails?.permissionLists?.some(
    (userPermission) =>
      userPermission.module === module && userPermission.permissionId === permissionId
  );
}

/**
 * This checks whether a user has a list of permissions by cross referencing the module id and permission id.
 * It uses the permission id and module id because they are enum values in the back office with least likely
 * chance to change.
 * @param module
 * @param permissions
 * @returns
 */
export function userHasModulePermissions(module, permissions) {
  let userDetails = UserSessionUtils.getUserDetails();
  if (userDetails?.isASuperAdmin) {
    return true;
  }
  return userPermissionsContains(userDetails?.permissionLists, module, permissions);
}

export function userPermissionsContains(userPermissionsList, module, permissionsArray) {
  return userPermissionsList?.some(
    (userPermission) =>
      userPermission.module === module && permissionsArray.includes(userPermission.permissionId)
  );
}

export function arrayContains(arrayObject, value) {
  return arrayObject?.some((object) => value === object);
}

export function getGoogleMapsURL(latitude, longitude) {
  return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
}

/**
 * This gets the default year range for the date picker to display when selecting a date in the past.
 * @returns
 */
export function getDefaultYearRange() {
  return `1900:${new Date().getFullYear() + 1}`;
}

/**
 * This gets the year range for a datepicker when selecting a date in the past and future
 * @param numberOfYearsBefore
 * @param numberOfYearsAfter
 * @returns
 */
export function getYearRange(numberOfYearsBefore, numberOfYearsAfter) {
  return `${new Date().getFullYear() - numberOfYearsBefore}:${
    new Date().getFullYear() + numberOfYearsAfter
  }`;
}

/**
 * This converts a string to all lower cases using the typescript fn of toLowerCase
 * @param stringToConvert
 * @returns
 */
export function convertToLowerCase(stringToConvert) {
  try {
    return stringToConvert?.toLowerCase();
  } catch (error) {
    return stringToConvert;
  }
}

/**
 * This templates returns formated text hyperlinked to the google maps page with the supplied coordinates
 * @param data :{ latitude; longitude }
 * @returns
 */
export const googleMapsLinkTemplate = (data) => {
  if (
    data?.latitude !== null &&
    data?.latitude !== undefined &&
    data?.longitude !== null &&
    data?.longitude !== undefined
  ) {
    return (
      <a
        className="hyper-link"
        href={getGoogleMapsURL(data?.latitude, data?.longitude)}
        target="_blank"
      >{`${data?.latitude}, ${data?.longitude}`}</a>
    );
  }
};

/**
 * Use this function to replace the default internal server error message with a
 * specific message of the object that failed to load
 * @param error
 * @param objectName
 * @returns
 */
export const sanitizeErrorMessage = (error, objectName) => {
  return error.message === INTERNAL_SERVER_ERROR
    ? formatString(FAILED_TO_LOAD_MESSAGE, objectName)
    : error.message;
};

/**
 * Truncates a string based on the max string size supplied
 * @param str
 * @param maxSize
 * @returns
 */
export const truncate = (str, maxSize) => {
  if (str === null || str === undefined) {
    return "";
  }
  return str.length > maxSize ? str.substring(0, maxSize) + "..." : str;
};

/**
 * Gives the status string custom styles
 * @param rowData
 * @returns
 */
export const statusBodyTemplate = (status) => {
  const bgName = "secondary";
  const knownStyle = toStyleClassName(status);
  return (
    <Badge pill bg={bgName}>
      {status}
    </Badge>
  );
};

export const renderPlainHtml = (content) => {
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};
/**
 * Replace underscore with space and capitalize each word.
 *
 * @param value
 * @returns
 */
export const replaceUnderscoreWithSpace = (value) => {
  if (value == null) return null;
  return value
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * To perform the binary-to-Base64 conversion of a file:
 * @param buffer
 * @returns
 */
export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

/**
 * This converts a number string to an integer using parseInt. If the nu
 * @param numberString
 * @returns
 */
export function convertStringToNumber(numberString) {
  if (numberString !== null) return parseInt(numberString);
  return numberString;
}
/**
 * Removes white spaces from a string
 * @param anyString
 * @returns
 */
export function removeWhiteSpaces(anyString) {
  if (isEmpty(anyString)) {
    return null;
  }
  return anyString.replace(/\s/g, "").toLocaleLowerCase();
}

/**
 * Cleans a string into the css style class name format
 * @param anyString
 * @returns
 */
export function toStyleClassName(anyString) {
  if (isEmpty(anyString)) {
    return null;
  }
  return removeWhiteSpaces(anyString)?.toLocaleLowerCase();
}

export function genericDateBodyTemplate(displayDate) {
  return <p>{toReadableDate(displayDate, true)}</p>;
}

export function genericAmountBodyTemplate(displayAmount) {
  return formatAmountWithCommas(displayAmount, null);
}

/**
 *
 * @param anyValue Returns true if a given object is null or undefined
 * @returns
 */
export function isEmpty(anyValue) {
  if (anyValue == undefined || anyValue == null || anyValue == "null") {
    return true;
  } else {
    return false;
  }
}

/**
 *
 * @param {*} anyValue
 * @returns
 */
export function isNotEmpty(anyValue) {
  return !isEmpty(anyValue);
}

/**
 * Converts a JSON object into a form data object
 * @param jsonData
 * @returns FormData object
 */
export function jsonToFormData(jsonData) {
  let accountProfileFormData = new FormData();
  for (var itemKey in jsonData) {
    if (jsonData[itemKey] !== undefined && jsonData[itemKey] !== null) {
      accountProfileFormData.append(itemKey, jsonData[itemKey]);
    }
  }
  return accountProfileFormData;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
