export const STORAGE_URL = "https://storage.bunnycdn.com/materialdesignnative";
export const ACCESS_KEY = "ba8dd74c-5844-4d83-8523b4a5f50d-a128-4190";
export const MAXIMUM_RECORDS_PER_PAGE = 50;

export const EmailRegex = "";
export const SupportedTerritories = "";

export const LOCALITY_TYPES = [
  {
    id: 0,
    name: "International",
  },
  {
    id: 1,
    name: "Local",
  },
];

export const COMPLEXITY_TYPES = [
  {
    id: 0,
    name: "Starter",
  },
  {
    id: 1,
    name: "Intemediate",
  },
  {
    id: 2,
    name: "Advanced",
  },
];

export const TASK_VERIFICATION_TYPES = [
  {
    id: 0,
    name: "Manual",
  },
  {
    id: 1,
    name: "Automatic",
  },
];